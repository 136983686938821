import React, { useState } from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CssBaseline from '@mui/material/CssBaseline'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles'

import Loading from './components/Loading'
import USER_PROMPTS from './content/user-prompts'
import SYSTEM_PROMPTS from './content/system-prompts'


let theme = createTheme({
  palette: {
    primary: {
      main: '#de6720',
    },
    secondary: {
      main: '#0077ea',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          margin: 0,
          background: '#fefefe',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          top: 0,
          left: 0,
          right: 0,
          zIndex: 0,
          height: '5px',
          display: 'block',
          overflow: 'hidden',
          position: 'absolute',
          backgroundColor: 'rgba(222, 103, 32, 0.25)',
        },
        bar: {
          backgroundColor: 'rgba(222, 103, 32, 1)',
        },
      },
    },
  },
})
theme = responsiveFontSizes(theme)


const App = () => {

  const [loading, setLoading] = useState(false)

  const [country, setCountry] = useState('mozambique')
  const [systemPrompt, setSystemPrompt] = useState(SYSTEM_PROMPTS[country])
  const [selectedPrompt, setSelectedPrompt] = useState('')
  const [userPrompt, setUserPrompt] = useState('')
  const [preamble, setPreamble] = useState('')
  const [response, setResponse] = useState('')

  const handleOnChangeCountry = event => {
    const value = event.target.value
    setCountry(value)

    // update system prompt
    setSystemPrompt(SYSTEM_PROMPTS[value])

    // reset user prompts
    setSelectedPrompt('')
    setUserPrompt('')
  }

  const handleOnChangeSystemPrompt = event => {
    setSystemPrompt(event.target.value)
  }

  const handleOnSelectPrompt = event => {

    // get and set the prompt key
    const promptKey = event.target.value
    setSelectedPrompt(promptKey)

    // get and set the prompt value
    const promptValue = USER_PROMPTS[country][promptKey]
    setUserPrompt(promptValue)
  }

  const handleOnChangeUserPrompt = event => {
    setUserPrompt(event.target.value)
  }

  const handleOnClick = event => {

    // Show loading indicator
    setLoading(true)
    setResponse('loading..')

    fetch(`/peer-${country}/get_response`, {
      'method': 'POST',
      'headers': {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify({
        'system': systemPrompt,
        'prompt': userPrompt,
        'preamble': preamble,
      }),
    })
      .then((response) => response.json())
      .then(data => {

        // update preamble
        setPreamble(data.preamble)

        // update response
        setResponse(data['response']['choices'][0]['message']['content'])

        // Remove loading indicator
        setLoading(false)
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/* Main Container */}
      <Box sx={{ flexGrow: 1 }}>

        {/* Header */}
        <AppBar position="static" sx={{ background: '#fff' }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ color: '#333', fontWeight: 'bold', flexGrow: 1 }}>
              {!loading && <span>VENICE Peer</span>}
              {loading && <Loading text='VENICE Peer - loading..' />}
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Content */}
        <Container maxWidth="xxl" sx={{ mt: 2, padding: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>

            {/* Left Panel */}
            <Box sx={{ width: '50%', marginRight: '16px', bgcolor: '#efefef', padding: '16px' }}>

              {/* Country Selection*/}
              <Box sx={{ width: '100%', mb: '16px', background: '#fefefe', p: '16px' }}>
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" sx={{ fontWeight: 'bold', color: '#333' }}>Country</FormLabel>
                  <RadioGroup
                    row
                    aria-label="country"
                    name="row-radio-buttons-group"
                    value={country}
                    onChange={handleOnChangeCountry}
                  >
                    <FormControlLabel value="mozambique" control={<Radio />} label="Mozambique" />
                    <FormControlLabel value="colombia" control={<Radio />} label="Colombia" />
                    <FormControlLabel value="mali" control={<Radio />} label="Mali" />
                  </RadioGroup>
                </FormControl>
              </Box>

              {/* System Prompt */}
              <Accordion key={'system-prompt'} defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: 'bold' }}>System Prompt</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    value={systemPrompt}
                    label="System Prompt"
                    variant="outlined"
                    multiline
                    rows={2}
                    fullWidth
                    onChange={handleOnChangeSystemPrompt}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Default Prompts */}
              <Box sx={{ width: '100%', mt: '16px', mb: '16px', background: '#fefefe' }}>
                <FormControl fullWidth>
                  <InputLabel id="prompt-select-label">Select a prompt</InputLabel>
                  <Select
                    labelId="prompt-select-label"
                    id="prompt-select"
                    value={selectedPrompt}
                    label="Select a prompt"
                    onChange={handleOnSelectPrompt}
                  >
                    {Object.keys(USER_PROMPTS[country]).map((key, index) => (
                      <MenuItem key={index} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* User Prompt */}
              <Accordion key={'prompt'} defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: 'bold' }}>Prompt</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    value={userPrompt}
                    label="Prompt"
                    variant="outlined"
                    multiline
                    rows={10}
                    fullWidth
                    onChange={handleOnChangeUserPrompt}
                    // Use the TextareaAutosize component for the multiline input
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      inputProps: {
                        minRows: 10, // Minimum number of rows the TextareaAutosize will occupy
                      },
                    }}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Submit Button */}
              <Box sx={{ marginTop: 2, marginBottom: 2, display: 'flex', justifyContent: 'right' }}>
                <Button variant="contained" color="primary" onClick={handleOnClick}>
                  Peer!
                </Button>
              </Box>

            </Box>

            {/* Right Panel */}
            <Box sx={{ width: '50%', marginLeft: '16px', bgcolor: '#efefef', padding: '16px', }}>

              <Typography sx={{ fontWeight: 'bold', mb: '16px' }}>Response</Typography>

              {/* System Response */}
              <TextField
                label=""
                variant="outlined"
                multiline
                rows={30}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                sx={{ flexGrow: 1 }}
                value={response}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  )
}


export default App
