const PROMPTS = {
  'mozambique': {
    'PWLWP SCEN 4: Rwandan Troops': `Imagine the following: To stem military advances by Al-Shabaab in the region, the number of Rwandan troops in Cabo Delgado has tripled. This has had a positive effect on Cabo Delgado, curbing insurgency-led violence in the region. Most people who previously fled Cabo Delgado have now returned home. Why do you think it likely that the arrival of Rwandan troops would cause most people who previously fled Cabo Delgado to now return home?
A. Al-Shabaab is no longer interested in targeting Cabo Delgado
B. People returned home to reunite with their families
C. The dramatic increase in Rwandan troops has made Cabo Delgado more secure
D. Those who were displaced felt unwelcomed in their new communities
E. They would return to Cabo Delgado for reasons not listed here
F. Don't know
G. Refuse

Please rank each option and provide a probability as a percentage.`,
    'PWLWP SCEN 6: Rumors': `Imagine that recently, there have been rumors within Maratane settlement about an impending attack on the settlement. Rumors about the threat of attack have been increasing in frequency, and tensions are high. However, most refugees have elected to remain in the camp amidst the threats. Why do you think it likely that most refugees would have elected to remain in the camp amidst the threats?
A. They lack alternate settlement options
B. They feel connected to the camp
C. Their relatives did not want to leave
D. They did not believe the threats
E. Refugees would remain in the camp for reasons not listed here.
F. Don’t know
G. Refuse

Please rank each option and provide a probability.

Provide an explanation of your ranking.`,
    'Causes PWLWP SCEN 4': `Imagine the following: To stem military advances by Al-Shabaab in the region, the number of Rwandan troops in Cabo Delgado has tripled. This has had a positive effect on Cabo Delgado, curbing insurgency-led violence in the region. Most people who previously fled Cabo Delgado have now returned home. Why do you think it likely that the arrival of Rwandan troops would cause most people who previously fled Cabo Delgado to now return home?

Provide the reasons as a bulleted list with a causal explanation of the reason. Please rank each reason and provide a probability.
    `,
    'Causes of Violence': `What are the causes of violence in Cabo Delgado?
    `,
    'Makua Concerns': `What are the major concerns of the Makua?`,
  },
  'colombia': {
    'PWLWP SCEN 2b: Armed Groups': `Imagine that the military turns against President Gustavo Petro, leading to a division in the country. In this chaos, an illegal armed group takes advantage of the situation and begins to violently take control of the villages on the Pacific Coast. As a result, people decide to stay; even people who live in cities move to the villages of the Pacific Coast.

Why do you think this is happening?
- Citizens are disgrunted with the national government, and are now supportive of the illegal armed group.
- Citizens are afraid that the government will implement measures against cities.
- Citizens want to leave but don’t want to abandon their families.
- The illegal armed group offers new and exciting job opportunities.
- None of the above.

Please rank each explanation and provide a numerical probability for its likelihood.`,
    'PWLWP SCEN 5: Illegal Mining': `Imagine that the government discovers illegal mining activity in a rural area. Military forces are sent to stop this activity and encounter resistance from the population that supports the miners.

Why do you think this is happening?

- Illegal mining is the primary source of income for locals.
- Locals have been forced by armed groups.
- Locals believe that illegal mining offers more benefits than legal activities.
- Locals do not see anything wrong and consider illegal mining as a legitimate activity.
- None of the above

Please rank each explanation and provide a numerical probability for its likelihood.`,
    'Illegal Mining': `What are the causes of illegal`,
    'Drug Trade': `What do you know about the drug trade?`,
    'Migration': `What migration patterns do you see?`,
  },
  'mali': {
    'PWLWP SCEN 1': `Imagine there's a new insurgency developing in Burkina that has spilled over the border into Mali, impacting Kadiolo Cercle and the region around it. Families have started rapidly selling off their cattle, accepting significantly lower prices. This is most likely because...

a. The demand for cattle has decreased throughout the region, making raising cattle no longer profitable.
b. The families noticed others in Côte d'Ivoire selling their cattle and decided to mimic the decision.
c. The families are worried about potential theft or slaughter by armed groups during the insurgency.
d. The families are worried about potential theft or slaughter by Malian forces during the insurgency.
e. The personal safety of families has been threatened by the insurgency and cattle farmers are selling off what they can before migrating elsewhere.
f. None of the above.

Please rank each explanation and provide a numerical probability for its likelihood.`,
    'PWLWP SCEN 2': `Imagine Kadiolo Cercle is affected by armed violence in the region. The government sends security forces to Kadiolo in response. As a result, beef prices increase. This is because...

a. The violence has made it hard for cattle farmers to take care of their animals and supply beef to the market, such as by obtaining feed and transporting their stock.
b. Cattle farmers have stopped production entirely and left their lands for fear of their personal safety and the safety of their families.
c. Government forces are extorting, stealing, or killing cattle.
d. Insurgents are extorting, stealing, or killing cattle.
e. None of the above.

Please rank each explanation and provide a numerical probability for its likelihood.`,
    'PWLWP SCEN 3': `Imagine a foreign government has decided to take advantage of the gold mining industry in Mali. This foreign government has worked with miners who exploit Mali’s mineral deposits without government authorization, encouraging them and acting against the interests of the Malian government. Those working in Syama Mine are leaving their jobs. This is likely because...

a. They are moving into mining activities unauthorized by the government , where they can earn more money.
b. Foreign actors or insurgent armed groups have forced miners into mining activities unauthorized by  the government .
c. Gold supply has increased, reducing its sale price, which is resulting in layoffs or reduced pay at Syama Mine.
d. Foreign actors or insurgent armed groups are violently targeting those continuing to work in legal mining.
e. None of the above.

Please rank each explanation and provide a numerical probability for its likelihood.`,
    'PWLWP SCEN 4': `Imagine there is political unrest in Kadiolo Cercle, and the price of gold does up. This increase in gold price might happen because...

a. People who want to keep their money safe during times of trouble buy more gold, which makes the price go up.
b. The government changes policies about gold production and trade with foreign nations, reducing the supply of gold.
c. The West African CFA Franc reduced in value, which means people need to spend more money to buy the same amount of gold.
d. Gold mining operations have been violently targeted by insurgent armed groups and government authorities, driving up production costs or stopping production.
e. None of the above

Please rank each explanation and provide a numerical probability for its likelihood.`,
    'PWLWP SCEN 5': `Imagine there is a conflict over land ownership in Kadiolo Cercle, among several villages claiming ownership of a large piece of land. This conflict leads to higher millet prices in the area. This is happening because...

a. The conflict makes it difficult for millet farmers to find enough land for their crops, leading to a decrease in millet supply and higher prices.
b. The uncertainty about land use makes it hard for millet farmers to plan.
c. The competition for land between the villages drives up the cost of land, making it more expensive for farmers to raise their crops.
d. Millet farmers who are continuing to work are being targeted in the conflict, driving down the number of people willing to participate in production and raising prices.
e. None of the above

Please rank each explanation and provide a numerical probability for its likelihood.`,
  },
}

export default PROMPTS
